<template>
  <div class="b2b-autocomplete" t-id="autocomplete-input">
    <div class="b2b-autocomplete__input" ref="test-ref">
      <telia-text-input
        :class="{ 'b2b-autocomplete__screenReaderOnlyLabel': screenReaderOnlyLabel }"
        t-id="autocomplete-input__input"
        :id="id"
        autocomplete="off"
        :label="labelText"
        :error-message="error"
        :value="input"
        :disabled="disabled"
        :required="isRequired"
        :required-error-message="requiredErrorMessage"
        :type="type"
        @click.prevent="onClick()"
        @input="onInput($event.target.value)"
        @keydown="onKeyDown($event.key.toLowerCase())"
        :aria-expanded="shouldExpand"
        aria-controls="autocomplete-input__suggestions"
        aria-haspopup="listbox"
        role="combobox"
      />
      <div :class="getIconClassList()">
        <slot name="symbol"></slot>
      </div>
    </div>
    <ul
      t-id="autocomplete-input__suggestions"
      id="autocomplete-input__suggestions"
      :class="getSuggestionContainerClassList()"
      @mouseleave="onMouseLeave()"
      :style="showSuggestionsStyles"
      role="listbox"
    >
      <li
        v-for="(suggestion, index) in filteredSuggestions"
        :key="`suggestion-${index}`"
        :class="getSuggestionClassList(index)"
        :name="`${id}-suggestion`"
        @mousemove="onMouseMove(index)"
        @mousedown.prevent="onMouseDown(index)"
        @mouseup="onMouseUp()"
      >
        <telia-p v-html="emboldMatchingCharacters(suggestion.listDisplayName)" />
      </li>
    </ul>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import escapeStringRegexp from "escape-string-regexp";
import { domEmit } from "./utils/customEmitHelper";

const NO_SUGGESTION_MARKED_INDEX = -1;

export default defineComponent({
  name: "AutocompleteInput",
  emits: ["dropdown-focused", "dropdown-blurred", "inputChange", "submit", "submitSuggestion"],
  props: {
    allowMultiplePartialMatches: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    error: { type: String, default: null },
    id: { type: String, required: true },
    ignoreCharactersInMatch: { type: Array, default: () => [] },
    initialInput: { type: String, default: null },
    initialSuggestionsCount: { type: Number, default: 0 },
    inputTransformationFunction: { type: Function, default: (x) => x },
    labelText: { type: String, default: null },
    maxHeightPixels: { type: [String, Number], default: null },
    onlyShowStartsWithMatches: { type: Boolean, default: true },
    isRequired: { type: Boolean, default: false },
    screenReaderOnlyLabel: { type: Boolean, default: false },
    requiredErrorMessage: { type: String, default: null },
    showExactMatchingSuggestions: { type: Boolean, default: true },
    suggestions: {
      type: Array,
      default: () => [],
      validator: (value) =>
        value.length === 0 ||
        // allow keys to act same as values (remap later), or object with value
        value.every(
          (item) =>
            typeof item === "string" ||
            typeof item === "number" ||
            item.value !== undefined ||
            item.value !== null
        ),
    },
    useDisplayName: { type: Boolean, default: false },
    type: { type: String, default: "text" },
  },
  data() {
    return {
      input: "",
      storedInput: "",
      showSuggestions: false,
      currentSuggestionIndex: NO_SUGGESTION_MARKED_INDEX,
      clickedSuggestionIndex: null,
      useMouseIndex: true,
      blurHandler: null,
    };
  },
  computed: {
    inputClassList() {
      let defaultClass = "b2b-autocomplete__input";

      if (this.error) {
        defaultClass += " b2b-autocomplete__input--invalid";
      }
      return defaultClass;
    },
    shouldExpand() {
      return this.filteredSuggestions.length > 0 && this.showSuggestions;
    },

    suggestionsWithDisplayNames() {
      return this.suggestions.map((suggestion) => {
        if (suggestion.listDisplayName !== undefined && suggestion.value !== undefined) {
          return suggestion;
        }

        return {
          listDisplayName: suggestion.listDisplayName || suggestion.value || suggestion,
          value: suggestion.value || suggestion,
        };
      });
    },

    filteredSuggestions() {
      if (!this.storedInput) {
        if (this.initialSuggestionsCount === 0) {
          // no input, no initial suggestions
          return [];
        } else if (
          this.initialSuggestionsCount === -1 ||
          this.initialSuggestionsCount >= this.suggestionsWithDisplayNames.length
        ) {
          // no input, initial suggestions count higher than the suggestions we actually have, offer it all
          return this.suggestionsWithDisplayNames;
        } else if (this.initialSuggestionsCount > 0) {
          // no input, initial suggestions count is lower than total suggestions, offer a subset0
          return this.suggestionsWithDisplayNames.slice(0, this.initialSuggestionsCount);
        }
      }

      let IsInputIsExactlySameAsTheSuggestion = (input, suggestion) => input === suggestion;

      let startsWithMatch = (input, suggestion) =>
        suggestion.toLowerCase().substring(0, input.length).toLowerCase() === input.toLowerCase();

      let isPartialMatch = (input, suggestion) =>
        suggestion.toLowerCase().includes(input.toLowerCase());

      if (this.allowMultiplePartialMatches) {
        return this.suggestionsWithDisplayNames.filter((suggestion) => {
          return this.transformedStoredInput
            .split(" ")
            .every((i) => isPartialMatch(i, suggestion.listDisplayName));
        });
      } else {
        return this.suggestionsWithDisplayNames.filter(
          (suggestion) =>
            (!IsInputIsExactlySameAsTheSuggestion(
              this.removeIgnoredCharacters(this.storedInput),
              this.removeIgnoredCharacters(suggestion.listDisplayName)
            ) ||
              this.showExactMatchingSuggestions) &&
            (this.onlyShowStartsWithMatches
              ? startsWithMatch(
                  this.removeIgnoredCharacters(this.storedInput),
                  this.removeIgnoredCharacters(suggestion.listDisplayName)
                )
              : isPartialMatch(
                  this.removeIgnoredCharacters(this.transformedStoredInput),
                  this.removeIgnoredCharacters(suggestion.listDisplayName)
                ))
        );
      }
    },

    getSelectedSuggestion() {
      return this.suggestionsWithDisplayNames.filter(
        (suggestion) =>
          this.removeIgnoredCharacters(suggestion.listDisplayName) ===
          this.removeIgnoredCharacters(this.input)
      )[0];
    },

    transformedStoredInput() {
      return this.inputTransformationFunction(this.storedInput);
    },

    dropdownStyles() {
      if (this.maxHeightPixels) {
        return {
          maxHeight: `${this.maxHeightPixels}px`,
        };
      }
      return {};
    },
    showSuggestionsStyles() {
      if (this.shouldExpand) {
        return {
          display: "block",
        };
      }
      return {
        display: "none",
      };
    },
  },
  methods: {
    emboldMatchingCharacters(value) {
      const embold = (value, rex) => value.replace(rex, (match) => `<strong>${match}</strong>`);

      if (this.allowMultiplePartialMatches) {
        const r = new RegExp(
          this.transformedStoredInput
            .split(" ")
            .map((x) => escapeStringRegexp(x))
            .join("|"),
          "" + "ig"
        );
        return embold(value, r);
      } else {
        const group = `[${this.ignoreCharactersInMatch
          .map((c) => escapeStringRegexp(c))
          .join("")}]*`;
        const r = this.transformedStoredInput
          .split("")
          .map((c) => escapeStringRegexp(c))
          .join(group);
        return embold(value, new RegExp(r, "i"));
      }
    },

    scrollToSuggestion() {
      const suggestionNodes = this.$el.querySelectorAll("#autocomplete-input__suggestions li");
      if (suggestionNodes && suggestionNodes.length > 0) {
        if (this.currentSuggestionIndex === NO_SUGGESTION_MARKED_INDEX) {
          suggestionNodes[0].scrollIntoView();
        } else {
          suggestionNodes[this.currentSuggestionIndex].scrollIntoView({
            block: "nearest",
          });
        }
      }
    },

    setSuggestionAsInput(index) {
      this.input = this.useDisplayName
        ? this.filteredSuggestions[index].listDisplayName
        : this.filteredSuggestions[index].value;
    },

    resetToStoredInput() {
      this.currentSuggestionIndex = NO_SUGGESTION_MARKED_INDEX;
      this.input = this.storedInput;
    },

    getIconClassList() {
      let defaultClass = "b2b-autocomplete__input-symbol";

      if (this.labelText && !this.screenReaderOnlyLabel) {
        defaultClass += " b2b-autocomplete__input-symbol--label-adjusted";
      }

      return defaultClass;
    },

    getSuggestionClassList(index) {
      let defaultClass = "b2b-autocomplete__suggestions__suggestion";

      if (this.currentSuggestionIndex === index) {
        defaultClass += " b2b-autocomplete__suggestions__suggestion--active";
      }
      return defaultClass;
    },

    getSuggestionContainerClassList() {
      let defaultClass = "b2b-autocomplete__suggestions";

      if (!this.labelText) {
        defaultClass += " b2b-autocomplete__suggestions__no-label";
      }
      return defaultClass;
    },

    getErrorClassList() {
      let defaultClass = "b2b-autocomplete__error";

      if (this.error) {
        defaultClass += " b2b-autocomplete__error--show";
      }
      return defaultClass;
    },

    onFocus() {
      this.resetToStoredInput();
      this.showSuggestions = true;
      domEmit(this.$el, "dropdown-focused");
    },

    onClick() {
      this.onFocus();
      document.addEventListener("click", this.shouldBlurHandler);
    },

    onBlur() {
      this.showSuggestions = false;
      this.resetToStoredInput();

      // scrolls to bottom of the page sometimes when no change was made, why?
      if (this.scrollToOptions) {
        this.scrollToSuggestion();
      }

      this.$emit("dropdown-blurred", this.input);
      domEmit(this.$el, "dropdown-blurred", this.input);
    },

    onInput(input) {
      const trimSpaces = (text) => text.replace(/\s+/g, " ");

      const trimmedInput = trimSpaces(input);
      this.input = trimmedInput;
      this.storedInput = trimmedInput;
      this.showSuggestions = true;
      domEmit(this.$el, "inputChange", this.input);
    },

    onKeyDown(key) {
      if (key === "enter") {
        this.onEnter();
      } else if (key === "tab") {
        this.onTab();
      } else if (key === "escape") {
        this.onEscape();
      } else if (key === "arrowdown") {
        this.onArrowDown();
      } else if (key === "arrowup") {
        this.onArrowUp();
      }
    },

    onEscape() {
      this.showSuggestions = false;
      this.resetToStoredInput();
    },

    onEnter() {
      this.submit();
    },

    onMouseMove(index) {
      this.useMouseIndex = true;
      this.currentSuggestionIndex = index;
      this.setSuggestionAsInput(index);
    },

    onMouseLeave() {
      this.currentSuggestionIndex = NO_SUGGESTION_MARKED_INDEX;
      this.input = this.storedInput;
    },

    onMouseDown(index) {
      this.clickedSuggestionIndex = index;
      this.setSuggestionAsInput(index);
    },

    onMouseUp() {
      if (this.clickedSuggestionIndex === this.currentSuggestionIndex) {
        this.clickedSuggestionIndex = null;
        this.submit();
      } else {
        this.clickedSuggestionIndex = null;
      }
    },

    onTab() {
      if (this.filteredSuggestions.length === 0) {
        this.input = this.storedInput;
      } else if (
        this.filteredSuggestions.length > 0 &&
        this.currentSuggestionIndex === NO_SUGGESTION_MARKED_INDEX
      ) {
        this.currentSuggestionIndex = 0;
        this.setSuggestionAsInput(0);
      } else if (
        this.filteredSuggestions.length > 0 &&
        this.currentSuggestionIndex !== NO_SUGGESTION_MARKED_INDEX
      ) {
        this.setSuggestionAsInput(this.currentSuggestionIndex);
      }
      this.submit();
    },

    onArrowUp() {
      this.useMouseIndex = false;
      this.showSuggestions = true;

      if (this.currentSuggestionIndex - 1 > NO_SUGGESTION_MARKED_INDEX) {
        this.currentSuggestionIndex--;
        this.setSuggestionAsInput(this.currentSuggestionIndex);
      } else if (this.currentSuggestionIndex - 1 === NO_SUGGESTION_MARKED_INDEX) {
        this.resetToStoredInput();
      } else {
        this.currentSuggestionIndex = this.filteredSuggestions.length - 1;
        this.setSuggestionAsInput(this.currentSuggestionIndex);
      }

      this.scrollToSuggestion();
    },

    onArrowDown() {
      this.useMouseIndex = false;
      this.showSuggestions = true;

      if (this.currentSuggestionIndex < this.filteredSuggestions.length - 1) {
        this.currentSuggestionIndex++;
        this.setSuggestionAsInput(this.currentSuggestionIndex);
      } else {
        this.resetToStoredInput();
      }

      this.scrollToSuggestion();
    },

    submit() {
      this.showSuggestions = false;
      this.currentSuggestionIndex = NO_SUGGESTION_MARKED_INDEX;
      this.storedInput = this.input;
      domEmit(this.$el, "submit", this.input);

      if (this.getSelectedSuggestion) {
        domEmit(this.$el, "submitSuggestion", this.getSelectedSuggestion);
      }
    },
    removeIgnoredCharacters(value) {
      const r = new RegExp(`[${this.ignoreCharactersInMatch.join("")}]`, "g");
      return value.replace(r, "");
    },
    shouldBlurHandler(event) {
      const composedPath = event.composedPath();
      if (!composedPath.includes(this.$el)) {
        document.removeEventListener("click", this.shouldBlurHandler);
        this.onBlur();
      }
    },
  },

  created() {
    if (this.initialInput) {
      this.input = this.initialInput;
      this.storedInput = this.input;
    }
  },
});
</script>

<style lang="scss">
@import "~@teliads/components/foundations/borders/variables";
@import "~@teliads/components/foundations/colors/variables";
@import "~@teliads/components/foundations/spacing/variables";

.b2b-autocomplete {
  position: relative;

  &__input {
    position: relative;
  }

  &__input-symbol {
    position: absolute;
    right: 0;
    top: 1.5rem;
    margin-right: 1.7rem;
    pointer-events: none;
    overflow: hidden;
    &--label-adjusted {
      top: 3.5rem;
    }
  }

  &__suggestions {
    position: absolute;
    z-index: 1000;
    overflow: auto;
    list-style: none;
    margin: 0;
    width: 100%;
    max-height: 20rem;
    box-sizing: border-box;
    border: $telia-border-width-1 solid $telia-gray-400;
    border-radius: $telia-border-radius-2;
    padding: 0;
    color: $telia-black;
    background-color: $telia-white;
    cursor: pointer;

    &__no-label {
      top: 5rem;
    }

    &__suggestion {
      padding: 1.5rem 1.7rem;
      border: $telia-border-width-1 solid transparent;
      border-bottom-color: $telia-gray-300;
      outline: none;

      &--active {
        background-color: $telia-gray-50;
        outline: $telia-border-width-2 solid $telia-blue-500;
        outline-offset: calc(-1 * $telia-border-width-2);
      }
    }
  }
}

.b2b-autocomplete__screenReaderOnlyLabel .telia-text-input__label {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  position: absolute;
  width: 1px;
}
</style>
